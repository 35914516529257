import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {prepareHeaders} from '../utils/prepareHeaders';
import {constants} from './_constants';

export const allApi = createApi({
  reducerPath: 'all',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    queryFarms: builder.query({
      query: constants.queryFarms,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    queryAdmins: builder.query({
      query: constants.queryAdmins,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    queryFarmMemberAccountInfoKate: builder.query({
      query: constants.queryFarmMemberAccountInfoKate,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    queryFarmMemberAccountInfo: builder.query({
      query: constants.queryFarmMemberAccountInfo,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    logout: builder.query({
      query: constants.logout,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    queryPersonalInvestment: builder.query({
      query: constants.queryPersonalInvestment,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
    adminLogin: builder.mutation({
      query: constants.adminLogin,
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),
  }),
});

export const {
  useQueryFarmsQuery,
  useQueryAdminsQuery,
  useQueryFarmMemberAccountInfoKateQuery,
  useQueryFarmMemberAccountInfoQuery,
  useLogoutQuery,
  useQueryPersonalInvestmentQuery,
  useAdminLoginMutation,
  usePrefetch,
} = allApi;
