// UI
import {Box} from '@mui/material';
import theme from './theme';
import 'react-toastify/dist/ReactToastify.css';

// Redux
import {useSelector} from 'react-redux';
import {selectDiscordUserInfo, IRoles} from './store/loginUserInfoSlice';
import {ThemeProvider, StyledEngineProvider} from '@material-ui/core';
// Routes
import {useRoutes} from 'react-router-dom';
// Types
import { getRoutes } from './routes';

function App() {
  const discordUserInfo = useSelector(selectDiscordUserInfo);
  const isManager = discordUserInfo?.roles?.includes(IRoles.MANAGER)
  const content = useRoutes(getRoutes(isManager))

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{content}</ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
