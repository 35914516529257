import {Navigate} from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import DefaultLayout from './components/DefaultLayout';
import Account from './pages/Account';
import CustomerList from './pages/CustomerList';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/SignIn';
import {LoginMainPage} from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import Register from './pages/Register';
import Settings from './pages/Settings';
import {DiscordAuthHandler} from './pages/Login/discordAuthHandler';
import {
  RegularUserDataPanel,
  REGULAR_USER_DATA_PANEL_PATH,
} from './pages/DataPanel/RegularUser';
import {UsernameLogin, USERNAME_LOGIN_PATH} from './pages/Login/UsernameLogin';

import {AdminDataGrid} from './components/dataGrid/Admin';

export const getRoutes = (isManager: boolean) => [
  {
    path: 'auth',
    element: <DefaultLayout />,
    children: [
      {path: 'discord', element: <DiscordAuthHandler />},
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {path: 'signin', element: <SignIn />},
      {path: 'login', element: <LoginMainPage />},
      {path: 'register', element: <Register />},
      {path: '404', element: <NotFound />},
      {path: '/', element: <Navigate to="/login" />},
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      {path: 'account', element: <Account />},
      {path: 'customers', element: <CustomerList />},
      {path: 'dashboard', element: <Dashboard />},
      {path: 'products', element: <ProductList />},
      {path: 'settings', element: <Settings />},
      {path: 'profile/coin', element: <AdminDataGrid />},
      {path: 'profile/invest', element: <AdminDataGrid />},
      {path: 'farmInfo/coin', element: isManager ? <AdminDataGrid /> : <Navigate to="/404" />},
      {path: 'farmInfo/invest', element: isManager ? <AdminDataGrid /> : <Navigate to="/404" />},
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
  {
    path: 'login',
    element: <DefaultLayout />,
    children: [
      {path: 'username', element: <UsernameLogin />},
      {path: '*', element: <Navigate to="/404" />},
    ],
  },
];
