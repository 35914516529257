import axios from 'axios';

export const REQUEST_URL_BASE = 'testdb.nfsc.global';

/** Actual Discord Auth */
export const DISCORD_AUTH_URL = `https://discordapp.com/api/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&scope=identify email&state=discord&redirect_uri=${process.env.REACT_APP_DISCORD_REDIRECT_URI}`;

/** My test Discord Auth */
// export const DISCORD_AUTH_URL = "https://discord.com/api/oauth2/authorize?client_id=1032735548055490580&redirect_uri=http%3A%2F%2Flocalhost%3A8080&response_type=code&scope=identify"

// const testRes = {
//   code: 0,
//   message: 'ok',
//   data: {
//     userInfo: {
//       IsCompleteInfo: false,
//       Token:
//         'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJZYW5kZXJlQm90MTMjMTYyNiIsImV4cCI6MTY2NjczODYyOH0.1X8C-Vy-XM5T6MzHwse8l9YiRZ6rPsNWDqTiOSYIkmM',
//       Discord: 'YandereBot13#1626',
//       Avatar:
//         'https://cdn.discordapp.com/avatars/715317504573964339/350528c252a927e7103fdaedd19c3abc',
//       Nickname: 'YandereBot13',
//     },
//     pageInfo: {
//       totalRows: 0,
//       page: 1,
//       pageSize: 50,
//       isFirstPage: true,
//       isLastPage: true,
//     },
//     list: [],
//   },
// };

export const adminLogin = async (username: string, password: string) => {
  return axios({
    method: 'get',
    url: '/user/login',
    params: {
      username: username,
      password: password,
    },
  });
};

export const logout = async (
  username: string,
  loginMode: 0 | 1,
  Token: string,
) => {
  const usernameParam =
    loginMode === 0 ? {username: username} : {discord: username};
  return axios({
    method: 'get',
    url: '/user/logout',
    params: {
      ...usernameParam,
      loginMode: loginMode,
    },
    headers: {
      Token: Token,
    },
  });
};

export const queryFarms = async (Token?: string) => {
  return axios({
    method: 'get',
    url: '/api/user/queryFarms',
    headers: {
      Token: Token,
    },
  });
};

export const queryAdmins = async (Token?: string) => {
  return axios({
    method: 'get',
    url: '/api/user/queryManagers',
    headers: {
      Token,
    },
  });
};

export const queryPersonalInvestment = async (Token?: string) => {
  return axios({
    method: 'get',
    url: '/api/detail/getPersonDetailInfo',
    headers: {
      Token,
    },
  });
};

export const queryFarmMemberAccountInfo = async (params, Token) => {
  return axios({
    method: 'get',
    url: '/api/detail/getDetailInfo',
    params,
    headers: {
      Token,
    },
  });
};

export const queryFarmMemberAccountInfoKate = async (params, Token) => {
  return axios({
    method: 'get',
    url: '/api/detail/getDetailInfo',
    params,
    headers: {
      Token,
    },
  });
};

export const queryFarmMemberList = async (Token) => {
  return axios({
    method: 'get',
    url: '/api/user/queryFarmUsers',
    headers: {
      Token,
    },
  });
};

export const queryAllFarmMembersInvestInfo = async (Token) => {
  return axios({
    method: 'get',
    url: '/api/invest/getFarmInvestInfo',
    params: {pageIndex: 1},
    headers: {
      Token,
    },
  });
};
export const queryAllFarmMembersHcoinInfo = async (Token) => {
  return axios({
    method: 'get',
    url: '/api/coininfo/getFarmCoinInfo',
    params: {pageIndex: 1},
    headers: {
      Token,
    },
  });
};
