import {stringify} from 'qs';

export const constants = {
  queryFarms: () => `/user/queryFarms`,
  queryAdmins: ({params}) => `/user/queryManagers` + stringify(params),
  queryFarmMemberAccountInfoKate: ({params}) =>
    `/detail/getDetailInfo?` + stringify(params),
  queryFarmMemberAccountInfo: () => `/detail/getDetailInfo`,
  logout: () => `/user/logout`,
  queryPersonalInvestment: () => `/detail/getPersonDetailInfo`,
  adminLogin: ({params}) => {
    return {
      url: `/user/login`,
      method: 'POST',
      body: params,
    };
  },

  //   postComment: ({ postId, params }) => {
  //     params = JSON.stringify(params);
  //     return {
  //       url: `/u/post/${postId}/comment`,
  //       method: 'POST',
  //       body: {
  //         content: params,
  //       },
  //     };
  //   },
};
