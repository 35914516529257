import {useEffect} from 'react';
import {toast} from 'react-toastify';
import Cookies from 'universal-cookie';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {setDiscordUserInfo} from '../../store/loginUserInfoSlice';

// Router
import {useNavigate, useLocation} from 'react-router-dom';
import {RootState} from '../../store/store';

const cookie = new Cookies();

export const DiscordAuthHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const discordInfo = useSelector(
    (state: RootState) => state.user.discordUserInfo,
  );
  //   console.log(
  //     'file: discordAuthHandler.tsx ---- line 17 ---- location',
  //     location
  //   );

  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const res = JSON.parse(`${urlSearchParams.get('data')}`);
  //   const { userInfo, pageInfo } = res?.data;
  //   const { Avatar, Discord, IsCompleteInfo, Nickname, Token } = userInfo;
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const res = JSON.parse(`${urlSearchParams.get('data')}`);
    console.log(
      'file: discordAuthHandler.tsx ---- line 34 ---- discordInfo',
      location,
      discordInfo,
    );
    if (discordInfo?.isLogin) {
      cookie.set('Token', discordInfo.token);
      navigate('/app/profile/coin');
      return;
    }

    const {userInfo, pageInfo} = res?.data || {};
    console.log(
      'file: discordAuthHandler.tsx ---- line 47 ---- userInfo',
      userInfo,
    );

    const {Avatar, Discord, Nickname, IsCompleteInfo, Roles, Token} =
      userInfo || {};

    if (!Token) {
      navigate('/login')
      toast.warning('Discord login failed');
    } else {
      dispatch(
        setDiscordUserInfo({
          discord: Discord,
          nickname: Nickname,
          avatar: Avatar,
          token: Token,
          isLogin: true,
          roles: Roles,
          isCompleteInfo: IsCompleteInfo,
        }),
      );
      navigate('/app/profile/coin');
      //   toast.success('登录成功');
      //   navigate('/app/dashboard');
    }
  }, []);

  return null;
};
