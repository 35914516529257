import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import {toast} from 'react-toastify';
import lodash from 'lodash'

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuid} from 'uuid';
import {
  LoginUserType,
  selectLoginUserType,
  selectAdminUserinfo,
  setAdminLogout,
  selectDiscordUserInfo,
  setDiscordUserLogout,
} from '../../store/loginUserInfoSlice';

// Mui
import {
  Paper,
  Box,
  TextField,
  Autocomplete,
  createFilterOptions,
  Typography,
  List,
  Menu,
  ListItemButton,
  ListItemText,
  Collapse,
  Tooltip,
} from '@mui/material';

import {DataGrid, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';

// API
import {
  queryFarmMemberAccountInfo,
  queryFarmMemberList,
  queryAllFarmMembersInvestInfo,
  queryAllFarmMembersHcoinInfo
} from '../../api';
// import {  } from '../../../api/types'

// Router
import { useNavigate, useLocation } from 'react-router-dom';

interface AutoCompleteInputFields {
  discord: string
  memberId: string
}

interface AutoCompleteOptions {
  discordList: string[]
  memberIdLIst: string[]
}

interface DataPanelState {
  columns: GridColDef[];
  rows: any[];
}

export const AdminDataGrid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // const loginUserType = useSelector(selectLoginUserType)
  // const adminUserInfo = useSelector(selectAdminUserinfo)
  const discordUserInfo = useSelector(selectDiscordUserInfo);

  const [textfieldInput, setTextFieldInput] = useState<string>('')

  const [autoCompleteInputs, setAutoCompleteInputs] = useState<AutoCompleteInputFields>({
    discord: '',
    memberId: '',
  })

  const [autoCompleteOptions, setAutoCompleteOptions] = useState<AutoCompleteOptions>({
    discordList: [],
    memberIdLIst: [],
  })

  const [showAutoComplete, setShowAutoComplete] = useState(false)
  
  // const displayUsername = loginUserType === LoginUserType.ADMIN ? adminUserInfo?.username : loginUserType === LoginUserType.DISCORD_USER ? discordUserInfo?.discord : ''
  const params = {
    discord: discordUserInfo.discord,
    pageIndex: 1,
  };
  const token = discordUserInfo.token;
  /** Nav menu states */

  // const NavMenuOptions: NavMenuOption[] = discordUserInfo.roles === 'MANAGER' ? [
  //   {
  //     label: PrimaryMenuNames.QueryPersonalInfo,
  //     subMenuOptions: [...Object.values(SubMenuNames)],
  //   },
  //   {
  //     label: PrimaryMenuNames.QueryFarmUserInfo,
  //     subMenuOptions: [...Object.values(SubMenuNames)],
  //   },
  //   // '修改农场用户账户信息',
  //   // '添加农场用户账户信息',
  // ]
  // :
  // [
  //   {
  //     label: PrimaryMenuNames.QueryPersonalInfo,
  //     subMenuOptions: [...Object.values(SubMenuNames)],
  //   },
  // ]

  // const [selectedMenuName, setSelectedMenuName] = useState<{
  //   primary: PrimaryMenuNames | null;
  //   secondary: SubMenuNames | null;
  //   expanded: PrimaryMenuNames | null; // Controls which primary menu is expanded
  // }>({
  //   primary: null,
  //   secondary: null,
  //   expanded: null,
  // });

  // const handlePrimaryMenuItemClick = (name: PrimaryMenuNames) => {
  //   setSelectedMenuName((prevState) => ({
  //     primary: prevState.primary === name ? null : name,
  //     secondary: SubMenuNames.HCoinInfo,
  //     expanded: prevState.expanded === name ? null : name,
  //   }));
  // };

  // const handleSubMenuItemClick = (name: SubMenuNames) => {
  //   if (selectedMenuName.primary === PrimaryMenuNames.QueryFarmUserInfo) {
  //     if (name === SubMenuNames.HCoinInfo) {
  //       queryAllFarmMembersHcoinInfo(token).then((res) => {
  //         const list = res?.data?.data?.list
  //         if (!list) {
  //           toast.warning(`接口数据为空, ${res.data.message}`)
  //         }
  //         const newCols = generateColumns(HCoinColumnNames)
  //         setDataPanelState({
  //           columns: newCols,
  //           rows: list,
  //         });
  //         const discordIdList = list.map((item) => item.discord)
  //         setAutoCompleteOptions((prevState) => ({
  //           ...prevState,
  //           discordList: discordIdList,
  //         }))
  //       })
  //     } else if (name === SubMenuNames.InvestInfo) {
  //       queryAllFarmMembersInvestInfo(token).then((res) => {
  //         const list = res?.data?.data?.list
  //         if (!list) {
  //           toast.warning(`接口数据为空, ${res.data.message}`)
  //         }
  //         const newCols = generateColumns(investColumnNames)
  //         setDataPanelState({
  //           columns: newCols,
  //           rows: list,
  //         });
  //         const discordIdList = list.map((item) => item.discord)
  //         setAutoCompleteOptions((prevState) => ({
  //           ...prevState,
  //           discordList: discordIdList,
  //         }))
  //       })
  //     }
  //   }
  //   setSelectedMenuName((prevState) => ({
  //     ...prevState,
  //     secondary: name,
  //   }));
  // };

  /** Data panel state */
  const [dataPanelState, setDataPanelState] = useState<DataPanelState>({
    columns: [],
    rows: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleAdminLogout = async () => {
    // if (adminUserInfo && adminUserInfo.username && adminUserInfo.token) {
    //   const resStatus = await logout(adminUserInfo.username, 0, adminUserInfo.token).then(res => res.status).catch(err => console.log(err))
    //   // Destroy credentials anyway, regardless of api response status
    //   toast.success("已登出")
    //   dispatch(setAdminLogout())
    // }
    toast.success('已登出');
    dispatch(setDiscordUserLogout());
    setTimeout(() => {
      navigate('/login');
    }, 1000);
  };

  const handleQueryButton = async () => {

  };

  // const handlePersonalInfoSubMenuSelect = () => {
  //   if (selectedMenuName.secondary === SubMenuNames.InvestInfo) {
  //     queryFarmMemberAccountInfo(params, token).then((res) => {
  //       const data = res.data.data;
  //       if (!data) {
  //         toast.warning(`接口数据为空, ${res.data.message}`)
  //       } else if (data.investInfo?.list?.length < 1) {
  //         toast.warning(`此用户没有投资信息`)
  //       }
  //       const newCols = generateColumns(investColumnNames)
  //       setDataPanelState({
  //         columns: newCols,
  //         rows: data.investInfo.list,
  //       });
  //     });
  //   } else if (selectedMenuName.secondary === SubMenuNames.HCoinInfo) {
  //     queryFarmMemberAccountInfo(params, token).then((res) => {
  //       const data = res.data.data
  //       if (!data) {
  //         toast.warning(`接口数据为空, ${res.data.message}`)
  //       }
  //       const newCols = generateColumns(HCoinColumnNames)
  //       const newRow = Object.assign(data.coinInfo, {hcnInfo: data?.hcnInfo})
  //       setDataPanelState({
  //         columns: newCols,
  //         rows: [newRow],
  //       });
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (selectedMenuName.expanded === PrimaryMenuNames.QueryPersonalInfo) {
  //     handlePersonalInfoSubMenuSelect();
  //   } else if (selectedMenuName.expanded === PrimaryMenuNames.QueryFarmUserInfo) {
  //     // Query farm user list
  //     // autoCompleteOptions.discordList.length === 0 && token && queryFarmMemberList(token).then((res) => {
  //     //   const farmUserList = res.data.data.list.map(item => item.discord)
  //     //   setAutoCompleteOptions((prevState) => ({
  //     //     ...prevState,
  //     //     discordList: farmUserList
  //     //   }))
  //     // })

  //   }
  // }, [selectedMenuName]);

  const [dataGridTitle, setDataGridTitle] = useState('')

  useEffect(() => {
    const locations = location.pathname.split("/")
    const subMenuPath = locations.pop()
    const primaryMenuPath = locations.pop()

    setShowAutoComplete(primaryMenuPath === 'farmInfo')
    setAutoCompleteInputs(() => ({
      discord: '',
      memberId: '',
    }))

    if (primaryMenuPath === 'profile') {
      if (subMenuPath === 'coin') {
        setDataGridTitle('My Coins')
        queryFarmMemberAccountInfo(params, token).then((res) => {
          const data = res.data.data
          if (!data) {
            toast.warning(`接口数据为空, ${res.data.message}`)
          }
          const newCols = generateColumns(HCoinColumnNames)
          const newRow = Object.assign(data.coinInfo, {hcnInfo: data?.hcnInfo})
          setDataPanelState({
            columns: newCols,
            rows: [newRow],
          });
        });
      } else if (subMenuPath === 'invest') {
        setDataGridTitle('My Investments')
        queryFarmMemberAccountInfo(params, token).then((res) => {
          const data = res.data.data;
          if (!data) {
            toast.warning(`接口数据为空, ${res.data.message}`)
          } else if (data.investInfo?.list?.length < 1) {
            toast.warning(`此用户没有投资信息`)
          }
          const newCols = generateColumns(investColumnNames)
          setDataPanelState({
            columns: newCols,
            rows: data.investInfo.list,
          });
        });
      }
    } else if (primaryMenuPath === 'farmInfo') {
      setDataGridTitle('Farm Member Coins')
      if (subMenuPath === 'coin') {
        queryAllFarmMembersHcoinInfo(token).then((res) => {
          const list = res?.data?.data?.list
          if (!list) {
            toast.warning(`接口数据为空, ${res.data.message}`)
          }
          const newCols = generateColumns(HCoinColumnNames)
          setDataPanelState({
            columns: newCols,
            rows: list,
          });
          const discordIdList = list.map((item) => item.discord)
          setAutoCompleteOptions((prevState) => ({
            ...prevState,
            discordList: discordIdList,
          }))
        })
      } else if (subMenuPath === 'invest') {
        setDataGridTitle('Farm Member Investments')
        queryAllFarmMembersInvestInfo(token).then((res) => {
          const list = res?.data?.data?.list
          if (!list) {
            toast.warning(`接口数据为空, ${res.data.message}`)
          }
          const newCols = generateColumns(investColumnNames)
          setDataPanelState({
            columns: newCols,
            rows: list,
          });
          const discordIdList = list.map((item) => item.discord)
          setAutoCompleteOptions((prevState) => ({
            ...prevState,
            discordList: discordIdList,
          }))
        })
      }
    }
  }, [location.pathname])

  const pressTestButton = async () => {
    // queryFarms().then(res => console.log(res))
    // queryFarmMemberAccountInfo().then(res => console.log(res))
    // console.log(discordUserInfo?.token);
    // queryPersonalInfoInvestment().then((res) => console.log(res))
    queryAllFarmMembersInvestInfo(token).then((res) => {
      console.log(res)
    })
  };

  // Auto Complete Input
  const AutoCompletefilterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (item: string) => item,
  });

  // const [loginUserType, setLoginUserType] = useState<LoginUserType>(LoginUserType.ADMIN)

  return (
    <Paper
      // elevation={1}
      sx={{
        display: 'flex',
        width: '90%',
        height: '80%',
        flexDirection: 'column',
        flex: 2,
        // alignItems: 'center',
        justifyContent: 'center',
        px: 4,
        py: 4,
        mx: 'auto',
        mt: 10,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          minHeight: '500px',
        }}
      >
        {/* <Paper
          elevation={2}
          sx={{
            display: 'flex',
            flex: 1,
            margin: '24px 20px 0px 0px',
            '&. MuiPaper-root': {
              height: '100%',
            },
          }}
        >
          <List sx={{width: '100%', border: '1px solid grey'}}>
            {Object.values(NavMenuOptions).map((option) => (
              <Fragment key={uuid()}>
                <ListItemButton
                  key={option.label}
                  sx={{margin: '6px 0px'}}
                  onClick={() => handlePrimaryMenuItemClick(option.label)}
                >
                  <Typography variant="h6">{option.label}</Typography>
                </ListItemButton>
                {option.subMenuOptions &&
                  option.subMenuOptions.map((name) => (
                    <Collapse
                      key={uuid()}
                      in={option.label === selectedMenuName.primary}
                    >
                      <List disablePadding>
                        <ListItemButton
                          sx={{
                            pl: 5,
                            backgroundColor:
                              selectedMenuName.secondary === name
                                ? '#F5F5F5'
                                : 'unset',
                          }}
                          onClick={() => handleSubMenuItemClick(name)}
                        >
                          <ListItemText primary={name} />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))}
              </Fragment>
            ))}
          </List>
        </Paper> */}

        <Box sx={{flex: 5}}>
          <Box sx={{margin: '10px 0px', display: 'flex', alignItems: 'center'}}>

            {showAutoComplete && 
              <Autocomplete
                // disabled={selectedMenuName.primary !== PrimaryMenuNames.QueryFarmUserInfo}
                disablePortal
                id="combo-box"
                options={autoCompleteOptions.discordList}
                sx={{
                  minWidth: '25%',
                  margin: '14px 20px 14px 0px',
                }}
                renderInput={(params) => <TextField {...params} label="Discord ID" variant="outlined" size='small' />}
                onChange={(event, value) => {
                  setAutoCompleteInputs((prevState) => ({
                    ...prevState,
                    discord: value,
                  }))
                }}
              /> 
            }

            {/* <TextField
              label={'Discord'}
              variant="outlined"
              size="small"
              //   value={
              //     selectedMenuName.primary !== PrimaryMenuNames.QueryFarmUserInfo
              //       ? ''
              //       : 'Jim#123'
              //   }
              disabled={
                selectedMenuName.primary !== PrimaryMenuNames.QueryFarmUserInfo
              }
              sx={{
                minWidth: '25%',
                margin: '14px 20px 14px 0px',
              }}
              onChange={(event) => {setTextFieldInput(event.currentTarget.value)}}
            /> */}

            {/* <TextField
              label={'Member ID'}
              variant="outlined"
              size="small"
              //   value={
              //     selectedMenuName.primary !== PrimaryMenuNames.QueryFarmUserInfo
              //       ? ''
              //       : '123456'
              //   }
              disabled={
                selectedMenuName.primary !== PrimaryMenuNames.QueryFarmUserInfo
              }
              sx={{
                minWidth: '25%',
                margin: '14px 20px 14px 0px',
              }}
            /> */}

            {/* <Button
              disabled={
                selectedMenuName.primary !== PrimaryMenuNames.QueryFarmUserInfo
              }
              variant="contained"
              onClick={handleQueryButton}
            >
              查询
            </Button> */}

            {/* <Button variant='contained' sx={{ marginLeft: '12px' }} onClick={pressTestButton} >
              Test
            </Button> */}
          </Box>

          <Box sx={{}}>
            
            <Typography variant='h4' sx={{ color: 'black', mb: '10px' }}>
              {dataGridTitle}
            </Typography>
            
            <DataGrid
              // loading={isLoading}
              columns={dataPanelState.columns}
              rows={
                autoCompleteInputs.discord ?
                  dataPanelState.rows.filter(item => item.discord === autoCompleteInputs.discord)
                  :
                  dataPanelState.rows
              }
              // rows={dataPanelState.rows}
              pageSize={10}
              disableSelectionOnClick
              disableColumnMenu
              autoHeight
              components={{
                BaseTooltip: Tooltip,
              }}
              sx={{
                '& .MuiDataGrid-main': {
                  overflow: 'initial',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-virtualScrollerContent': {
                  minHeight: '100%'
                }
              }}
              disableDensitySelector
              // experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const generateColumns = (fields: string[]) => {
  const result: GridColDef[] = fields.map((field) => ({
    field: field,
    headerName: lodash.startCase(field),
    width: field === 'discord' ? 240 : 120,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  }))
  return result
}

const investColumnNames = [
  'discord',
  'memberId',
  'farmId',
  'currency',
  'sendAmount',
  'amountDiff',
  'investorName',
  'sendDate',
  'receiveLast4',
  'notesInvestor',
  'receivedDate',
  'matchMark',
  'matchResult',
  'notesPayee',
  'receivedAmount',
  'accountCode',
  'payer',
  'payee',
  'proof',
  'id',
  'createTime',
  'updateTime',
  'remark',
]

const HCoinColumnNames = [
  'discord',
  'memberId',
  'farmId',
  'hcnInfo',
  "benefitHcn",
  "icoHcn",
  "holdForOthers",
  "holdByOthers",
  "email",
  "investHcn",
  "toFmv",
  "id",
  "createTime",
  "updateTime",
  "remark",
]

export const ADMIN_DATA_PANEL_PATH = '/admin/panel';