import React, {useEffect, useState, useCallback} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {selectDiscordUserInfo} from '../../store/loginUserInfoSlice';

// MUI
import {
  Box,
  Button,
  SvgIcon,
  Typography,
  TypographyProps,
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Grid,
  Divider,
} from '@mui/material';
import {SupervisorAccount} from '@mui/icons-material';

// Components
import {toast} from 'react-toastify';
import {ReactComponent as DiscordLogo} from './discord_logo.svg';

// API
import {DISCORD_AUTH_URL} from '../../api';

// Router
import {useNavigate, useLocation} from 'react-router-dom';
import {USERNAME_LOGIN_PATH} from './UsernameLogin';
import {ADMIN_DATA_PANEL_PATH} from '../DataPanel/Admin';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Theme,
} from '@mui/material/styles';

const theme = createTheme();

export const LoginMainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const discordUserInfo = useSelector(selectDiscordUserInfo);

  /** Discord auth states & managers */
  const [authWindowIsOpen, setAuthWindowIsOpen] = useState<boolean>(false);

  const handleDiscordLogin = useCallback(() => {
    if (discordUserInfo?.token) {
      toast.success('已登录');
      navigate(ADMIN_DATA_PANEL_PATH);
    } else {
      window.open(DISCORD_AUTH_URL, '_self');
    }
  }, [discordUserInfo?.token]);

  // const timer = useRef<NodeJS.Timer | null>(null)
  // const popup = useRef<Window | null>(null)

  // useEffect(() => {
  //   if (authWindowIsOpen) {
  //     popup.current = window.open(DISCORD_AUTH_URL, '_blank', 'popup')
  //     timer.current = setInterval(() => {
  //       popup.current?.closed && setAuthWindowIsOpen(false)
  //     }, 500)
  //   } else
  //   // else {
  //   //   setDotCount(0)
  //   //   timer.current && clearInterval(timer.current)
  //   // }
  //   return () => {
  //     // Clean up on component dismount (route change)
  //     popup.current && popup.current.close()
  //     timer.current && clearInterval(timer.current)
  //   }
  // }, [authWindowIsOpen])

  useEffect(() => {
    if (discordUserInfo?.isLogin) {
      //   toast.success('登录成功');
      //   setAuthWindowIsOpen(false)
      navigate('/app/dashboard');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{height: '100vh'}}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 6,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar data-id="qqqqq" sx={{m: 1, bgcolor: 'secondary.main'}}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{mt: 1}}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
            {/* 
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: '#5865f2',
                textTransform: 'none',
              }}
              startIcon={
                <SvgIcon sx={{ width: '40px', height: '40px' }}>
                  <DiscordLogo />
                </SvgIcon>
              }
              onClick={handleDiscordLogin}
            >
              <Typography variant="h6">
                {authWindowIsOpen
                  ? `等待用户完成授权....`
                  : '使用 Discord 登录'}
              </Typography>
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{
                maxWidth: 'max-content',
                height: '56px',
                textTransform: 'none',
              }}
              startIcon={
                <SvgIcon sx={{ width: '40px', height: '40px' }}>
                  <SupervisorAccount />
                </SvgIcon>
              }
              onClick={() => navigate(USERNAME_LOGIN_PATH)}
            >
              <Typography variant="h6">{'用户名密码登录'}</Typography>
            </Button> */}
          </Box>
          <Divider sx={{mx: 6}}>
            <Typography variant="h5" sx={{p: '0px 10px', color: '#808080'}}>
              OR
            </Typography>
          </Divider>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: '#5865f2',
                textTransform: 'none',
              }}
              startIcon={
                <SvgIcon sx={{width: '36px', height: '36px'}}>
                  <DiscordLogo />
                </SvgIcon>
              }
              onClick={handleDiscordLogin}
            >
              <Typography variant="h6">{'使用 Discord 登录'}</Typography>
            </Button>
          </Box>
          <Copyright sx={{mt: 5}} />
        </Grid>

        {/* image */}
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url(/photo/ricardo-gomez-angel-otf25n2UETg-unsplash.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};

export const LOGIN_MAIN_PAGE_PATH = '/login';

const Copyright: React.FC<TypographyProps> = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
